/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        // Make post images linked and clickable
        'home': {
            init: function () {
                $('article.post').each(function (index, elem) {
                    $('img', elem).eq(0).wrap('<a href="' + $('h2 > a', elem).attr('href') + '"></a>');
                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        },
        // Make embedded videos scale on mobile devices
        'single_post': {
            init: function () {
                $("iframe[src*='youtube'], iframe[src*='vimeo'], iframe[src*='player.with.in']").wrap('<div class="video-container"></div>');
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


// GA tracking for file downloads
//http://www.blastam.com/blog/how-to-track-downloads-in-google-analytics
//
jQuery(function ($, undefined) {
    $('[href$="zip"],' +
        '[href$="pdf"],' +
        '[href$="doc"],' +
        '[href$="xls"],' +
        '[href$="ppt"],' +
        '[href$="mp3"],' +
        '[href$="mpg"],' +
        '[href$="mpeg"],' +
        '[href$="mp4"]' +
        '').on('click', function (e) {
        e.preventDefault();
        window.open(this.href, '_blank');
    });
});
